import React, { FC, ReactNode, useEffect, useState } from "react";
import { HazardImageUpload } from "./HazardImageUpload";
import { HazardAnalysis } from "./HazardAnalysis";
import { useHazardContext } from "./hooks/useHazardContext";

interface HazardComponentProps {
  bottomNavComponent?: ReactNode;
}

export const HazardComponent: FC<HazardComponentProps> = ({
  bottomNavComponent,
}) => {
  const [openImageUpload, setOpenImageUpload] = useState<boolean>(false);
  const [openHazardAnalysis, setOpenHazardAnalysis] = useState<boolean>(false);
  const [isAnotherPhoto, setIsAnotherPhoto] = useState<boolean>(false);
  const {
    photosUploadInProgress,
    setHazardImageUrl,
    setHazardImageFile,
    setPhotosUploadInProgress,
  } = useHazardContext();

  const handleCloseHazardAnalysis = () => {
    setOpenHazardAnalysis(false);
    setHazardImageUrl(null);
    setHazardImageFile(null);
  };

  const handleProccessImage = () => {
    setOpenImageUpload(false);
    setOpenHazardAnalysis(true);
  };

  const handleUploadAnotherPhoto = () => {
    setIsAnotherPhoto(true);
    handleCloseHazardAnalysis();
    setOpenImageUpload(true);
  };

  useEffect(() => {
    if (photosUploadInProgress) {
      handleProccessImage();
      setPhotosUploadInProgress(false);
    }
  }, [photosUploadInProgress]);

  return (
    <>
      <HazardImageUpload
        openImageUpload={openImageUpload}
        setOpenImageUpload={setOpenImageUpload}
        onProcessImage={handleProccessImage}
        isAnotherPhoto={isAnotherPhoto}
        setIsAnotherPhoto={setIsAnotherPhoto}
        bottomNavComponent={bottomNavComponent}
      />

      <HazardAnalysis
        openHazardAnalysis={openHazardAnalysis}
        onCloseHazardAnalysis={handleCloseHazardAnalysis}
        onUploadAnotherPhoto={handleUploadAnotherPhoto}
      />
    </>
  );
};
