import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import { API, useAPI } from "@rtslabs/field1st-fe-common";
import styles from "./ReportsCard.module.scss";
import { Components } from "shared/src/qa-slugs";
import { sliceColors } from "../clientAdmin/reports/reportsConstants";
import { Select } from "shared/src/components/Select/Select";
import { TimeFilter, timeFilterOptions } from "shared/src/data/timeFilters";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { PieChart } from "shared/src/components/Charts/PieChart/PieChart";
import { DataPoint } from "./types";
import { useTheme } from "styled-components";
import { Control } from "./Control";
import { ControlProps, GroupBase } from "react-select";

interface UserReportsCardProps {
  filters: API.GetDocumentsArgs;
  onFilterByFormType: (formTypeName?: number) => void;
  participantId: number;
  setTimeFilter: Dispatch<SetStateAction<TimeFilter>>;
  timeFilter: TimeFilter;
  userName: string;
}

export const UserReportsCard: FC<UserReportsCardProps> = ({
  filters,
  onFilterByFormType,
  participantId,
  setTimeFilter,
  timeFilter,
  userName,
}) => {
  const theme = useTheme();
  const formTypeStatsCall = useAPI(API.getParticipantFormSubmissionStats, {
    ...filters,
    participantId,
  });

  const totalDocumentsCount = (formTypeStatsCall?.data ?? []).reduce(
    (acc, obj) => acc + obj.totalDocuments,
    0
  );

  const sortedAndSlicedData = (formTypeStatsCall?.data ?? [])
    .sort((a, b) => b.totalDocuments - a.totalDocuments)
    .slice(0, 4);

  const remainingData = (formTypeStatsCall?.data ?? [])
    .sort((a, b) => b.totalDocuments - a.totalDocuments)
    .slice(4);

  const othersTotalDocuments = remainingData.reduce(
    (acc, obj) => acc + obj.totalDocuments,
    0
  );

  const finalData = [
    ...sortedAndSlicedData,
    ...(othersTotalDocuments > 0
      ? [
          {
            totalDocuments: othersTotalDocuments,
            formTypeName: "Others",
            formTypeId: undefined,
          },
        ]
      : []),
  ];

  let data = useMemo(() => {
    let _data: DataPoint[] = finalData.map((r, i) => ({
      color: sliceColors[i].value,
      id: r.formTypeName,
      qa: sliceColors[i].name,
      value: r.totalDocuments,
    }));

    let allReportTypesWithData = finalData.filter((r) => r.totalDocuments > 0);

    if (allReportTypesWithData && allReportTypesWithData.length > 4) {
      _data.push({
        color: sliceColors[4].value,
        id: "other",
        qa: sliceColors[4].name,
        value:
          totalDocumentsCount - _data.reduce((acc, d) => (acc += d.value), 0),
      });
    }
    return _data;
  }, [finalData, totalDocumentsCount]);

  return (
    <section
      className={joinClassNames(styles.profileCard, styles.userProfileCard)}
    >
      <div className={styles.header}>
        <h2 className={styles.sectionLabel}>{userName}'s REPORTS</h2>
        <Select
          aria-label="Filter reports by time period"
          qa={`${Components.ReportsCard}-dropdown`}
          className={styles.dropdown}
          options={timeFilterOptions}
          value={timeFilter ?? TimeFilter.ALL_TIME}
          onChange={(option) =>
            setTimeFilter(option?.value || TimeFilter.ALL_TIME)
          }
          components={{
            // This type cast is gross. Figure out something better that isn't <any>.
            Control: Control as React.ComponentType<
              ControlProps<
                { value: TimeFilter; label: string },
                false,
                GroupBase<{ value: TimeFilter; label: string }>
              >
            >,
          }}
          styles={{
            control: (provided) => {
              return {
                ...provided,
              };
            },
            container: (provided) => ({
              ...provided,
            }),
            valueContainer: (provided) => ({
              ...provided,
            }),
            menu: (provided) => ({ ...provided }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              color: theme.colors.primary,
            }),
          }}
        />
      </div>

      <div className={styles.pieChartWrapper}>
        <div className={styles.pieChart}>
          <PieChart
            ariaLabel="Pie chart showing the proportion of reports for each form type."
            data={data}
            labels={false}
            innerRadius={0.7}
            startAngle={-180}
            endAngle={180}
            borderWidth={0.01}
            fill={{ type: "Solid" }}
            sliceColors={{ datum: "data.color" }}
            centeredContent={
              <div
                className={joinClassNames(
                  styles.pieChartCenterText,
                  styles.overlay
                )}
              >
                <div className={styles.centerTextWrapper}>
                  <div className={styles.statusText}>Total:</div>
                  <div className={joinClassNames(styles.countText)}>
                    {totalDocumentsCount}
                  </div>
                </div>
              </div>
            }
            qa={`${Components.ReportsCard}-Chart`}
            onClick={(r) => {
              if (r.data.id !== "other") {
                const formTypeId = finalData.find(
                  (d) => d.formTypeName === r.data.id
                )?.formTypeId;
                onFilterByFormType(formTypeId);
              }
            }}
          />
        </div>
      </div>

      <div className={styles.footerLegend}>
        {finalData.map((r, i) => {
          return (
            <button
              aria-label={`Filter by ${r.formTypeName}`}
              data-testid={`${Components.ReportsCard}-${r.formTypeName}-legendButton`}
              key={r.formTypeName}
              className={joinClassNames(
                styles.legendItem,
                !r.formTypeId && styles.notClickable
              )}
              onClick={() => onFilterByFormType(r.formTypeId)}
            >
              <div
                className={styles.legendItemColor}
                style={{ backgroundColor: sliceColors[i].value }}
              ></div>
              <span>{r.formTypeName}</span>
            </button>
          );
        })}
      </div>
    </section>
  );
};
