import React, {
  Dispatch,
  FC,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Popper from "../common/Popper";
import styles from "./HazardComponent.module.scss";
import { PhotoTarget } from "../Photos/PhotoTarget";
import { usePhotoWidget } from "../photoWidget/usePhotoWidget";
import { ClickAwayListener } from "@mui/base";
import { HazardIcon } from "../../assets/svg/HazardIcon";
import { joinClassNames } from "../../helpers/theme.helpers";

interface HazardImageUploadProps {
  openImageUpload: boolean;
  setOpenImageUpload: Dispatch<SetStateAction<boolean>>;
  onProcessImage: (imageUrl: string) => void;
  isAnotherPhoto: boolean;
  setIsAnotherPhoto: Dispatch<SetStateAction<boolean>>;
  bottomNavComponent?: ReactNode;
}

export const HazardImageUpload: FC<HazardImageUploadProps> = ({
  openImageUpload,
  setOpenImageUpload,
  onProcessImage,
  isAnotherPhoto,
  setIsAnotherPhoto,
  bottomNavComponent,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const { handleUploadPhoto } = usePhotoWidget();

  const handleClose = () => {
    !isAnotherPhoto && setOpenImageUpload(false);
  };

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setTimeout(() => setOpenImageUpload(!openImageUpload));
  };

  //   const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
  //     switch (event.key) {
  //       case "Escape":
  //         handleClose();
  //         event.preventDefault();
  //         break;
  //     }
  //   };

  useEffect(() => {
    isAnotherPhoto && setIsAnotherPhoto(false);
  }, [isAnotherPhoto]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!!bottomNavComponent && (
          <span onClick={handleOpen}>{bottomNavComponent}</span>
        )}
        {!bottomNavComponent && (
          <span onClick={handleOpen}>
            <HazardIcon className={joinClassNames(styles.hazardIcon)} />
          </span>
        )}
        <Popper
          arrow={!bottomNavComponent}
          placementOffset={30}
          anchorEl={anchorEl}
          onClose={handleClose}
          open={openImageUpload}
          placement="top-end"
          className={styles.hazardPopper}
        >
          <div className={styles.popperWrapper}>
            <span className={styles.hazardTitle}>
              Image Hazard Identification
            </span>

            <div className={styles.photoUploadContainer}>
              <PhotoTarget
                type={"IMAGES_BELOW"}
                onUploadPhoto={handleUploadPhoto}
                questionTitle={"Image Hazard Identification"}
                autoOpenFilePicker={!!bottomNavComponent}
                isHazardAi
                singleFileUpload
              />
            </div>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
