export enum HazardActionTypes {
  SET_PHOTOS_UPLOAD_IN_PROGRESS = "SET_PHOTOS_UPLOAD_IN_PROGRESS",
  SET_HAZARD_IMAGE_URL = "SET_HAZARD_IMAGE_URL",
  SET_HAZARD_IMAGE_FILE = "SET_HAZARD_IMAGE_FILE",
}

export interface HazardImageUrlAction {
  value: URL | null;
  type: typeof HazardActionTypes.SET_HAZARD_IMAGE_URL;
}

export interface HazardImageFileAction {
  value: File | null;
  type: typeof HazardActionTypes.SET_HAZARD_IMAGE_FILE;
}

export interface HazardPhotoUploadInProgressAction {
  value: boolean;
  type: typeof HazardActionTypes.SET_PHOTOS_UPLOAD_IN_PROGRESS;
}

export type HazardActions =
  | HazardImageUrlAction
  | HazardImageFileAction
  | HazardPhotoUploadInProgressAction;
