import React, { useState, useRef, useEffect, SyntheticEvent } from "react";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import {
  CompositeDecorator,
  ContentState,
  convertToRaw,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
} from "draft-js";
import "draft-js/dist/Draft.css";

import Label from "../../Label/Label";

import * as S from "./styles";
import {
  BlockStyleControls,
  InlineStyleControls,
  IndentControls,
  IndentDirection,
  LinkControls,
} from "./StyleControls";
import {
  addLinkEntity,
  customEntityTransform,
  findLinkEntities,
  getEditorClassName,
  getEntityKeyFromSelection,
  getIndentedState,
  getSelectionAnchor,
  getStateFromHTML,
  removeLinkEntity,
} from "./helpers";
import useDebounce from "../../../util/hooks/useDebounce";
import { VirtualElement } from "../../common/Popper/Popper";
import { FieldMessagingWrapper } from "../../Document/DocumentForm/FieldMessagingWrapper";

export type RichTextEditorProps = {
  error?: string;
  initialContent?: string;
  updateContent?: string | null;
  label?: string;
  labelClassName?: string;
  onBlur?: (event: SyntheticEvent) => void;
  onChangeContent?: (value?: string) => void;
  placeholder?: string;
  className?: string;
  qa?: string;
  required?: boolean;
  useHtmlRenderer?: boolean;
};

/**
 * Shorthand for retrieving current text value of editor state
 * @param state
 */
function textOf(state: EditorState): string {
  return state.getCurrentContent().getPlainText();
}

const RichTextEditor = ({
  error,
  initialContent = "",
  updateContent,
  label,
  labelClassName,
  onBlur,
  onChangeContent,
  placeholder,
  className,
  qa,
  required,
  useHtmlRenderer,
}: RichTextEditorProps) => {
  // popperAnchor describes where the link builder (or any other future popper) should be shown
  const [popperAnchor, setPopperAnchor] = useState<VirtualElement | null>(null);
  const [inputHasFocus, setInputHasFocus] = useState<boolean>(true);

  // current state of the editor
  const [editorState, setEditorState] = useState<EditorState>();

  const handleSetEditorStateWithHTML = (content: string) => {
    const contentBlock = htmlToDraft(content);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );

    const editorState = EditorState.createWithContent(contentState);

    setEditorState(editorState);
  };

  const handleSetEditorState = (content: string) => {
    // decorate link entities with the Link component
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: S.Link,
      },
    ]);
    if (!editorState) {
      setEditorState(getStateFromHTML(content, decorator));
    } else {
      const incomingState = getStateFromHTML(content, decorator);
      if (textOf(incomingState) !== textOf(editorState)) {
        setEditorState(incomingState);
      }
    }
  };

  // update the editor state with the initial content
  useEffect(() => {
    useHtmlRenderer
      ? handleSetEditorStateWithHTML(initialContent)
      : handleSetEditorState(initialContent);
  }, []);

  useEffect(() => {
    if (!(updateContent && updateContent !== initialContent)) return;
    useHtmlRenderer
      ? handleSetEditorStateWithHTML(updateContent)
      : handleSetEditorState(updateContent);
  }, [updateContent]);

  // Ref used to focus the editor
  const editorRef = useRef<Editor>(null);

  // Ref used to store the scroll position of the editor.
  // This is used to maintain scroll position when the editor content changes.
  const scrollPosition = useRef(0);
  useEffect(() => {
    const editorField = document.getElementById("wysiwyg-editor");
    if (editorField) {
      editorField.scrollTop = scrollPosition.current;
    }
  });

  const debouncedOnChange = useDebounce({
    method(htmlContent?: string) {
      onChangeContent && onChangeContent(htmlContent);
    },
  });

  const handleChange = (newState: EditorState) => {
    setEditorState(newState);

    /** convert the editor content to HTML and pass it to onChangeContent */
    const content = newState.getCurrentContent();
    const htmlContent = !content.hasText()
      ? ""
      : draftToHtml(
          convertToRaw(content),
          undefined,
          undefined,
          customEntityTransform
        );
    debouncedOnChange(htmlContent);

    // when the focus changes, set the new link popper anchor
    if (editorState && textOf(newState) === textOf(editorState)) {
      handleSelectionChange(newState);
    }
  };

  /* Editor has not yet been initialized */
  if (!editorState) {
    return null;
  }

  /** add or remove a block type from a selection, or toggle for next content changes */
  const handleToggleBlockType = (blockType: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  /** add or remove an inline style from a selection, or toggle for next content changes */
  const handleToggleInlineStyle = (inlineStyle: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  /**
   * add or remove indent from a paragraph or list item
   * @param e Mouse or keyboard event
   * @param indentDirection 'increase' or 'decrease'
   */
  const handleChangeIndent = (e: any, indentDirection?: IndentDirection) => {
    e.preventDefault();
    if (indentDirection === "decrease") {
      e.shiftKey = true;
    }
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    // increase list item indent
    if (
      currentBlockType === "ordered-list-item" ||
      currentBlockType === "unordered-list-item"
    ) {
      setEditorState(RichUtils.onTab(e, editorState, 4));
    }
    // increase text indent
    else {
      const direction =
        indentDirection || (e.shiftKey ? "decrease" : "increase");
      const newState = getIndentedState(editorState, direction);
      setEditorState(newState);
    }
  };

  /** set the popper anchor to the selected text */
  const getPopperAnchor = () => {
    const anchor = getSelectionAnchor();
    if (anchor) setPopperAnchor(anchor);
  };

  const handleSelectionChange = (newState: any) => {
    const entityKey = getEntityKeyFromSelection(newState);
    const selection = newState.getSelection();

    if (!entityKey && !inputHasFocus && selection.isCollapsed()) {
      setPopperAnchor(null);
    } else {
      getPopperAnchor();
    }
  };

  /**
   * Handle typical key bindings, such as Cmd+B, Cmd+U.
   * For more info, visit https://draftjs.org/docs/quickstart-rich-styling#richutils-and-key-commands
   */
  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  /** Key Binding map for handleKeyCommand */
  const mapKeyToEditorCommand = (e: any) => {
    // disabling keyboard tab for accessibility
    // if (e.keyCode === 9 /* TAB */) {
    //   handleChangeIndent(e);
    //   return null;
    // }
    return getDefaultKeyBinding(e);
  };

  /**
   * attach a link entity to a selected string
   * @param linkUrl the url to add to the string
   */
  const addLink = (linkUrl: string) => {
    const newState = addLinkEntity(editorState, linkUrl);
    setEditorState(newState);
    handleChange(newState);
  };

  /** remove link entity from the selected string */
  const removeLink = () => {
    const newState = removeLinkEntity(editorState);
    setEditorState(newState);
  };

  // Build a classname for the editor based on the current state and errors
  const editorClassName = getEditorClassName(editorState, !!error);

  return (
    <FieldMessagingWrapper className={className} error={error}>
      {label && (
        <Label
          className={labelClassName}
          htmlFor="wysiwyg-editor"
          required={required}
          hasError={!!error}
        >
          {label}
        </Label>
      )}
      <S.StyleButtonsWrapper className="wysiwyg-style-buttons">
        <InlineStyleControls
          editorState={editorState}
          onToggle={handleToggleInlineStyle}
        />
        <BlockStyleControls
          editorState={editorState}
          onToggle={handleToggleBlockType}
        />
        <IndentControls onToggle={handleChangeIndent} />
        <LinkControls
          onFocusInput={() => setInputHasFocus(true)}
          onBlurInput={() => setInputHasFocus(false)}
          popperAnchor={popperAnchor}
          editorState={editorState}
          onAddLink={addLink}
          onRemoveLink={removeLink}
        />
      </S.StyleButtonsWrapper>

      <S.EditorField
        className={editorClassName}
        id="wysiwyg-editor"
        onClick={() => {
          const editorField = document.getElementById("wysiwyg-editor");
          if (editorField) {
            scrollPosition.current = editorField.scrollTop;
          }
          editorRef.current?.focus();
        }}
        data-testid={qa}
      >
        <Editor
          ref={editorRef}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder={placeholder}
          spellCheck
          keyBindingFn={mapKeyToEditorCommand}
        />
      </S.EditorField>
    </FieldMessagingWrapper>
  );
};

export default RichTextEditor;
