import React, { FC } from "react";
import { components, ControlProps } from "react-select";
import { Components } from "shared/src/qa-slugs";
import { Icon } from "shared/src/components/Icon/Icon";
import styles from "./ReportsCard.module.scss";

interface CustomControlProps extends ControlProps {
  children: React.ReactNode;
}

export const Control: FC<CustomControlProps> = ({ children, ...props }) => (
  <components.Control
    {...props}
    data-testid={`${Components.ReportsCard}-dropdownControl`}
  >
    <Icon
      className={styles.reportIcon}
      type={"icon-icons8-calendar"}
      color={"#0172CE"}
    />
    {children}
  </components.Control>
);
