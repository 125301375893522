import React, { FC, useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import styles from "./HazardComponent.module.scss";
import { joinClassNames } from "../../helpers/theme.helpers";
import { Button, SecondaryButton } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { Functions } from "@rtslabs/field1st-fe-common";
import scssVariables from "../../sass/jsExports.module.scss";
import { useHazardContext } from "./hooks/useHazardContext";
import { HazardAnalyzingMessage } from "./HazardAnalyzingMessage";
import { formatJsonToHtml, formatJsonToText } from "./hazard.helpers";
import { useMediaQuery } from "react-responsive";

interface HazardContentProps {
  onUploadAnotherPhoto?: () => void;
  onSaveHazardInfoToDoc?: (hazardInfo: string) => void;
  hideUpload?: boolean;
}

export const HazardContent: FC<HazardContentProps> = ({
  onUploadAnotherPhoto,
  onSaveHazardInfoToDoc,
  hideUpload,
}) => {
  const { hazardImageUrl, hazardImageFile } = useHazardContext();
  const [loading, setLoading] = useState(true);
  const [messageIsStreaming, setMessageIsStreaming] = useState<boolean>(false);
  const [messages, setMessages] = useState<string>();
  const [copyText, setCopyText] = useState<boolean>(false);
  const [imageRetries, setImageRetries] = useState<number>(0);
  const [base64Image, setBase64Image] = useState<string | null>();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${scssVariables.minDesktop})`,
  });

  const getMessages = async () => {
    if (!hazardImageUrl && !hazardImageFile) return setMessages(undefined);

    if (hazardImageUrl) {
      try {
        await Functions.analyzeImageWithUrl({
          url: hazardImageUrl.href,
          setMessages,
          setMessageIsStreaming,
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (hazardImageFile) {
      try {
        await Functions.analyzeImageWithFile({
          file: hazardImageFile,
          setMessages,
          setMessageIsStreaming,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCopyText = () => {
    messages && navigator.clipboard.writeText(formatJsonToText(messages));
    setCopyText(true);
    setTimeout(() => setCopyText(false), 3000);
  };

  const handleUploadAnotherPhoto = () => {
    onUploadAnotherPhoto && onUploadAnotherPhoto();
    setMessages(undefined);
    setLoading(true);
  };

  useEffect(() => {
    if (!!hazardImageFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result as string);
      };
      reader.readAsDataURL(hazardImageFile);
      getMessages();
    }

    if (!!hazardImageUrl) {
      getMessages();
    }
  }, [hazardImageUrl, hazardImageFile]);

  useEffect(() => {
    if (messageIsStreaming) {
      setLoading(false);
    }
  }, [messageIsStreaming]);

  return (
    <>
      <div
        className={joinClassNames(
          styles.titleBar,
          loading && styles.hazardHide
        )}
      >
        <div className={styles.title}>Identifying Potential Hazards...</div>
        {/* <div className={styles.cancel}>Cancel</div> */}
      </div>
      <div
        className={joinClassNames(
          styles.hazardContent,
          loading && styles.hazardContentLoading
        )}
      >
        {isDesktop && (
          <div
            className={joinClassNames(
              styles.hazardMessage,
              loading && styles.messageLoading
            )}
          >
            {messages && (
              <div
                className={styles.hazardMessageText}
                id="gpt-messages"
                dangerouslySetInnerHTML={{ __html: formatJsonToHtml(messages) }}
              />
            )}
          </div>
        )}

        <div
          className={joinClassNames(
            styles.hazardImageContainer,
            !loading && styles.imageFinish
          )}
        >
          {!!hazardImageUrl && (
            <img
              src={hazardImageUrl.href}
              alt="hazard"
              data-name="hazard"
              onError={() => {
                if (imageRetries < 3) {
                  setImageRetries(imageRetries + 1);
                  const hazardImage = document.querySelector(
                    'img[data-name="hazard"]'
                  ) as HTMLImageElement;
                  if (hazardImage) {
                    hazardImage.src = hazardImageUrl.href;
                  }
                }
              }}
            />
          )}
          {!!hazardImageFile && base64Image && (
            <img src={base64Image} alt="hazard" data-name="hazard-file" />
          )}
        </div>

        {!isDesktop && (
          <div
            className={joinClassNames(
              styles.hazardMessage,
              loading && styles.messageLoading
            )}
          >
            {messages && (
              <div
                className={styles.hazardMessageText}
                id="gpt-messages"
                dangerouslySetInnerHTML={{ __html: formatJsonToHtml(messages) }}
              />
            )}
          </div>
        )}
        <div
          className={joinClassNames(
            styles.loadingFFAnimation,
            !loading && styles.hideLoading
          )}
        >
          <Player
            autoplay
            loop
            src="/assets/lottieFiles/F1st-Animation.json"
            className={styles.player}
          />
        </div>
      </div>
      <div
        className={joinClassNames(
          styles.desciption,
          !loading && styles.hazardHide
        )}
      >
        {!hazardImageUrl && !hazardImageFile && (
          <span>Uploading in progress...</span>
        )}
        {(!!hazardImageUrl || !!hazardImageFile) && <HazardAnalyzingMessage />}
        {/* <span>Cancel</span> */}
      </div>
      <div
        className={joinClassNames(
          styles.buttonsContainer,
          loading && styles.hazardHide
        )}
      >
        <Button
          disabled={copyText || messageIsStreaming}
          onClick={handleCopyText}
        >
          {copyText && <Icon type="icons8-checkmark" color="green" />}
          {!copyText && <Icon type="copy-text" />}
          {isDesktop && <span>{copyText ? "Text Copied!" : "Copy Text"}</span>}
          {!isDesktop && <span>{copyText ? "Copied!" : "Copy"}</span>}
        </Button>
        {!hideUpload && (
          <SecondaryButton onClick={handleUploadAnotherPhoto}>
            <Icon type="icons8-add_file1" />
            <span>Upload{isDesktop && " Another Photo"}</span>
          </SecondaryButton>
        )}
        {!!onSaveHazardInfoToDoc && messages && (
          <SecondaryButton
            disabled={messageIsStreaming}
            onClick={() => onSaveHazardInfoToDoc(formatJsonToHtml(messages))}
          >
            <Icon type="icons8-pencil_fill" />
            <span>Add{isDesktop && " to Document"}</span>
          </SecondaryButton>
        )}
      </div>
    </>
  );
};
