import React, { FC, ReactNode, useReducer } from "react";
import { HazardProvider } from "./HazardContext";
import { HazardContextProps, HazardState } from "./types";
import reducer from "./hooks/reducer";
import {
  HazardActionTypes,
  HazardImageFileAction,
  HazardImageUrlAction,
  HazardPhotoUploadInProgressAction,
} from "./hooks/actions";

interface HazardAnalysisProviderProps {
  children: ReactNode;
}

export const HazardAnalysisProvider: FC<HazardAnalysisProviderProps> = ({
  children,
}) => {
  const initialDocSecState: HazardState = {
    hazardImageUrl: null,
    hazardImageFile: null,
    photosUploadInProgress: false,
  };

  const [state, dispatch] = useReducer<
    (state: any, action: any) => HazardState
  >(reducer, initialDocSecState);

  const value: HazardContextProps = {
    ...state,
    setHazardImageUrl: (value) => {
      dispatch({
        type: HazardActionTypes.SET_HAZARD_IMAGE_URL,
        value,
      } as HazardImageUrlAction);
    },
    setHazardImageFile: (value) => {
      dispatch({
        type: HazardActionTypes.SET_HAZARD_IMAGE_FILE,
        value,
      } as HazardImageFileAction);
    },
    setPhotosUploadInProgress: (value) => {
      dispatch({
        type: HazardActionTypes.SET_PHOTOS_UPLOAD_IN_PROGRESS,
        value,
      } as HazardPhotoUploadInProgressAction);
    },
  };

  return <HazardProvider value={value}>{children}</HazardProvider>;
};
