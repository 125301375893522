import { useState } from "react";
import { useHazardContext } from "../HazardAnalysis/hooks/useHazardContext";

export interface UploadingPhoto {
  key: number;
  src: string;
  file: File;
}

export interface PhotosHandling {
  handleUploadPhoto: (newFiles: File[]) => Promise<void>[];
}

export function usePhotoWidget(): PhotosHandling {
  const [photosUploading, setPhotosUploading] = useState<UploadingPhoto[]>([]);
  const { setHazardImageFile, setPhotosUploadInProgress } = useHazardContext();

  function handleUploadPhoto(newFiles: File[]) {
    setPhotosUploadInProgress(true);
    let key = photosUploading.values.length;
    const uploads: UploadingPhoto[] = newFiles.map((file) => ({
      key: key++,
      file: file,
      src: URL.createObjectURL(file),
    }));

    setPhotosUploading((prev) => prev.concat(uploads));

    return uploads.map((upload) => processIndividualUpload(upload));
  }

  async function processIndividualUpload(upload: UploadingPhoto) {
    setHazardImageFile(upload.file);
  }

  return {
    handleUploadPhoto,
  };
}
