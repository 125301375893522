import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  API,
  DocumentStatus,
  DocumentSummaryVm,
  FormTypeDTO,
  ReportingSubmissionStatsVm,
  SortDirection,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import { DateRange } from "shared/src/components/DateRange/DateRange";
import Loader from "shared/src/components/Loader/Loader";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import { FilterSelect } from "shared/src/components/Select/FilterSelect";
import { SelectOption } from "shared/src/components/Select/Select";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import { Pagination } from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import {
  DateRangeValue,
  getMinMaxSubmissionDatesFromTimeFilter,
  TimeFilter,
} from "shared/src/data/timeFilters";
import { Components, ElementType } from "shared/src/qa-slugs";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { timeFilterOptions } from "shared/src/data/timeFilters";
import { userWriteRoles } from "../../../routes/constants/permissionSets";
import {
  DocumentDrawer,
  DocumentDrawerSection,
} from "../documents/DocumentDrawer";
import * as UserStyles from "../users/styles";
import Modal from "shared/src/components/common/Modal";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import Popper from "shared/src/components/common/Popper";
import { LegacyStatsCard } from "../../common/StatsCard";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "shared/src/components/common/styled/Table";
import DefaultCell from "../../common/TableUI/DefaultCell";
import { default as CommonText } from "shared/src/components/common/Text";
import { prettifyDocumentStatus } from "../../Documents/helpers";
import { generateGroupOptions } from "shared/src/components/clientAdmin/helpers";
import { SubmitButton, Text } from "shared/src/components/clientAdmin/styles";
import { DeleteDocumentConfirmation } from "../users/modalContent/DeleteDocumentConfirmation";
import { PopperChevron, PopperRow, PopperWrapper } from "../users/styles";
import StickyProfileHeader from "./components/StickyProfileHeader";
import * as S from "./styles";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import styles from "./ViewUserProfile.module.scss";
import { Icon } from "shared/src/components/Icon/Icon";
import { UserReportsCard } from "../../ReportsCard/UserReportsCard";

interface ViewUserProfileProps {
  authToken: string;
  dispatch?: any;
  formTypes?: FormTypeDTO[];
}

interface Params extends Record<string, string> {
  id: string;
}

interface Sorting {
  [key: string]: {
    direction: SortDirection;
    type?: string;
  };

  primarySort: {
    direction: SortDirection;
    type: string;
  };
}

// constants

const DeleteDocumentMenuItem = ({
  loading,
  error,
  closeMenu,
  openConfirmationModal,
}: {
  loading: boolean;
  error: boolean;
  closeMenu: () => void;
  openConfirmationModal: () => void;
}) => {
  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();
  return (
    <Loader loading={loading}>
      <S.DeleteDocumentButton
        onClick={() => {
          openConfirmationModal();
          closeMenu();
        }}
      >
        Delete {documentTermLower} (permission required)
        {error && (
          <CommonText className="ml-2" variant="error">
            An error occurred
          </CommonText>
        )}
      </S.DeleteDocumentButton>
    </Loader>
  );
};

const statusOptions: { value: DocumentStatus | undefined; label: string }[] = [
  { value: undefined, label: "All Statuses" },
  { value: DocumentStatus.NEW, label: "New" },
  { value: DocumentStatus.SUBMITTED, label: "Submitted" },
  { value: DocumentStatus.IN_PROGRESS, label: "In Progress" },
];

const formTypeOptions = (
  formTypes: FormTypeDTO[] = []
): SelectOption<number | undefined>[] => {
  const formTypeOptions = formTypes.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  return [{ value: undefined, label: "All Form Types" }, ...formTypeOptions];
};

/** helper function to get label for totalDocuments box (left most) */
function getDocumentsStatsNameIfSubmissionsFilterApplied(
  dateRange: DateRangeValue | undefined,
  timeFilter: TimeFilter,
  documentsTerm: string
): string {
  const { minSubmissionDate } = getMinMaxSubmissionDatesFromTimeFilter(
    timeFilter,
    dateRange
  );

  if (minSubmissionDate) {
    return `${documentsTerm} submitted since ${moment(minSubmissionDate).format(
      "MM/DD/YY"
    )}`;
  }

  return documentsTerm;
}

/** helper function to extend ReportingSubmissionStatsVm */
function getStat(
  key: string,
  data?: ReportingSubmissionStatsVm | null
): string | number | JSX.Element | undefined {
  if (!data) {
    return;
  }
  let stat: number = 0;
  if (key === "totalDocuments") {
    stat = data.totalSubmissions + data.totalOutstanding;
  } else if (key === "completeRateOfSubmissions") {
    stat =
      (100 * data.totalSubmissions) /
      (data.totalSubmissions + data.totalOutstanding);
    return stat.toFixed(0);
  } else if (key in data) {
    stat = data[key];
  } else {
    console.error("Stat not found in ReportingSubmissionStatsVm: " + key);
  }
  return stat;
}

const listGroups = (allGroups, primaryGroup) => {
  if (!allGroups || allGroups.length === 0) {
    return "-";
  }

  // Sort groups to move the primary group to the front
  const sortedGroups = [...allGroups].sort((x, y) =>
    x.id === primaryGroup ? -1 : y.id === primaryGroup ? 1 : 0
  );

  return (
    <>
      {sortedGroups.map((group, index) => (
        <span key={group.id}>
          {primaryGroup === group.id && <S.StarIcon />}
          {group.name}
          {index !== sortedGroups.length - 1 && ", "}
        </span>
      ))}
    </>
  );
};

export const ViewUserProfile = (props: ViewUserProfileProps) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const [remoteClear, setRemoteClear] = useState<boolean>(false);

  const formTypesCall = useAPI(API.getAllFormTypes);
  const allGroupCall = useAPI(API.getClientGroups, {
    size: 200,
    sort: "name,asc",
  });

  // Group config terms
  const documentsTerm = useGroupTerm(
    "documents",
    "noun",
    "plural",
    "Documents"
  );
  const documentsTermLower = documentsTerm.toLowerCase();
  const documentTerm = useGroupTerm("document", "noun", undefined, "Documents");
  const documentTermLower = documentTerm.toLowerCase();
  const supervisorTerm = useGroupTerm(
    "supervisor",
    "noun",
    undefined,
    "Supervisor"
  );

  // Get user id from url
  const participantId = Number(params.id);

  // Sort
  const [sorting, setSorting] = useState<Sorting>({
    // Order is by appearance in table
    title: {
      direction: "asc",
    },
    ["clientGroup.name"]: {
      direction: "asc",
    },
    ["formTypeIds"]: {
      direction: "asc",
    },
    startDate: {
      direction: "asc",
    },
    submissionDate: {
      direction: "asc",
    },
    status: {
      direction: "asc",
    },
    primarySort: {
      // Not a column, table's sorted by 1 column at all times
      direction: "desc",
      type: "submissionDate",
    },
  });

  const sortBy = (type, direction) => {
    setSorting({
      ...sorting,
      [type]: {
        direction,
      },
      primarySort: {
        direction,
        type,
      },
    });
  };

  // pagination
  const [documentsPage, setDocumentsPage] = useState(0);
  const [documentsSize, setDocumentsSize] = useState(10);

  // Filters
  const [group, setGroup] = useState<number | undefined>();
  const [status, setStatus] = useState<DocumentStatus | undefined>();
  const [formType, setFormType] = useState<number | undefined>();
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState<DateRangeValue | undefined>(
    undefined
  );
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(TimeFilter.ALL_TIME);

  // Values for stat cards above the documents table
  const statsMap = useMemo(() => {
    const box2and3notAvailable = status && !["SUBMITTED"].includes(status); // If submission type is set and not "Submitted"
    return [
      {
        key: "totalDocuments",
        name: getDocumentsStatsNameIfSubmissionsFilterApplied(
          dateRange,
          timeFilter,
          documentsTerm
        ),
      },
      {
        key: "averageTimeToComplete",
        name: "Average Time to Complete",
        notAvailable: box2and3notAvailable,
        type: "time",
        valueName: "minutes",
      },
      {
        key: "completeRateOfSubmissions",
        name: "Completion Rate of Submissions",
        notAvailable: box2and3notAvailable,
        valueName: "percent",
      },
    ];
  }, [status, dateRange, timeFilter]);

  const { minSubmissionDate, maxSubmissionDate } =
    getMinMaxSubmissionDatesFromTimeFilter(timeFilter, dateRange);

  const userStatsCall = useAPI(API.getParticipantSubmissionStats, {
    clientGroupId: group,
    formTypeId: formType,
    maxSubmissionDate,
    minSubmissionDate,
    participantId,
    query: searchQuery,
    status: status,
  });

  const userCall = useAPI(API.getUserByParticipantId, { participantId });

  const [isFiltered, setIsFiltered] = useState(false);

  const handleClearFilters = () => {
    setGroup(undefined);
    setFormType(undefined);
    setStatus(undefined);
    setTimeFilter(TimeFilter.ALL_TIME);
    setSearchQuery("");
    setRemoteClear(true);
    setIsFiltered(false);
  };

  const handleFilterByFormType = (formTypeId?: number) => {
    setFormType(formTypeId);
  };

  useEffect(() => {
    setRemoteClear(false);
  }, [remoteClear]);

  const checkIfFiltered = (): void => {
    const isDefault =
      !status &&
      timeFilter === TimeFilter.ALL_TIME &&
      !formType &&
      !group &&
      !searchQuery;

    setIsFiltered(!isDefault);
  };

  useEffect(() => {
    checkIfFiltered();
  }, [formType, group, searchQuery, status, timeFilter]);

  // On filter change, get documents/aggregate stats and reset `currentPage`
  useEffect(() => {
    setDocumentsPage(0);
  }, [
    group,
    formType,
    maxSubmissionDate,
    minSubmissionDate,
    status,
    participantId,
    searchQuery,
    documentsSize,
    sorting,
  ]);

  const filters: API.GetDocumentsArgs = {
    clientGroupId: group,
    formTypeId: formType,
    maxSubmissionDate,
    minSubmissionDate,
    status,
    ownerId: participantId,
    query: searchQuery,
    page: documentsPage,
    size: documentsSize,
    sort: [sorting.primarySort.type, sorting.primarySort.direction],
  };

  const documentsCall = useAPI(API.getDocuments, filters);

  const participant = useMemo(
    () => userCall.data?.participant,
    [userCall.data]
  );
  const supervisor = useMemo(() => userCall.data?.supervisor, [userCall.data]);
  const clientGroups = useMemo(
    () => userCall.data?.clientGroups,
    [userCall.data]
  );

  // Is sticky header showing
  const mainContent = document.getElementById("contentWrapper");
  const [stickyHeaderIsShowing, setStickyHeaderIsShowing] = useState(false);
  mainContent &&
    mainContent.addEventListener(
      "scroll",
      () => {
        if (mainContent.scrollTop > 200) {
          if (!stickyHeaderIsShowing) setStickyHeaderIsShowing(true);
        } else {
          if (stickyHeaderIsShowing) setStickyHeaderIsShowing(false);
        }
      },
      true
    );

  // Action menu
  // const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const [showDocumentDrawer, setShowDocumentDrawer] = useState<
    DocumentDrawerSection | undefined
  >();
  const [popperIsOpen, setPopperIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Delete document
  const [deleteDocumentError, setDeleteDocumentError] = useState(false);
  const [deleteDocumentIsLoading, setDeleteDocumentIsLoading] = useState(false);
  const [
    deleteDocumentConfirmationModalIsVisible,
    setDeleteDocumentConfirmationModalIsVisible,
  ] = useState(false);
  const [
    deleteDocumentOutcomeModalIsVisible,
    setDeleteDocumentOutcomeModalIsVisible,
  ] = useState(false);
  const [anchorDocument, setAnchorDocument] = useState<DocumentSummaryVm>();

  // View
  const viewDocument = useCallback(() => {
    // In this table, we'll always view the document in sidebar first -- GK
    if (anchorDocument && anchorDocument.id) {
      setPopperIsOpen(false);
      setShowDocumentDrawer(DocumentDrawerSection.Document); // View doc
    }
  }, [anchorEl]);

  // Unmount
  useEffect(() => {
    return () => {
      mainContent &&
        mainContent.removeEventListener("scroll", () => true, true);
    };
  }, []);

  const qaBase = Components.ViewUser;
  const paths = [
    { pathName: "People", href: "/people/users" },
    { pathName: "Users", href: "/people/users" },
    {
      pathName: `User Profile: ${userCall.data?.name || ""}`,
      href: `/people/users/view/${participantId}`,
    },
  ];

  return (
    <>
      {/* Drawer has Document History, Delete Document and View Document  */}
      {showDocumentDrawer && anchorDocument?.id && (
        <DocumentDrawer
          closeDrawer={() => {
            setShowDocumentDrawer(undefined);
          }}
          documentId={anchorDocument.id}
          show={showDocumentDrawer}
          onClose={() => {
            setShowDocumentDrawer(undefined);
          }}
        />
      )}
      {/* Modal for deleting a user */}
      <Modal
        qa={`${qaBase}-${ElementType.Modal}-deleteDocument-${
          anchorDocument && anchorDocument.id
        }`}
        border="none"
        open={deleteDocumentConfirmationModalIsVisible}
        handleClose={() => {
          setDeleteDocumentConfirmationModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <DeleteDocumentConfirmation
            // user data
            created={anchorDocument && anchorDocument.dateCreated}
            group={
              anchorDocument &&
              anchorDocument.clientGroup &&
              anchorDocument.clientGroup.name
            }
            title={anchorDocument && anchorDocument.title}
            // rest
            loading={deleteDocumentIsLoading}
            onCancel={() => setDeleteDocumentConfirmationModalIsVisible(false)}
            onSubmit={async () => {
              setDeleteDocumentIsLoading(true);
              try {
                if (anchorDocument && anchorDocument.id) {
                  await API.deleteDocumentById({ id: anchorDocument.id });
                  setDeleteDocumentError(false);
                  setShowDocumentDrawer(undefined); // Close drawer if document is deleted
                }
              } catch (e) {
                setDeleteDocumentError(true);
              } finally {
                setDeleteDocumentIsLoading(false);
                setDeleteDocumentConfirmationModalIsVisible(false);
                setDeleteDocumentOutcomeModalIsVisible(true);
              }
            }}
            qa={`${qaBase}-${anchorDocument && anchorDocument.id}`}
          />
        }
      />
      {/* Modal for the outcome when deleting a document */}
      <Modal
        border="none"
        open={deleteDocumentOutcomeModalIsVisible}
        handleClose={() => {
          !deleteDocumentError && documentsCall.refresh();
          setDeleteDocumentError(false);
          setDeleteDocumentOutcomeModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <UserStyles.ModalPromptWrapper>
            <UserStyles.ModalPromptTitle>
              {deleteDocumentError
                ? "Error: Document could not be deleted"
                : "Document has been deleted"}
            </UserStyles.ModalPromptTitle>
            <UserStyles.ModalPromptSubtitle>
              {deleteDocumentError
                ? `A problem has occurred while attempting to delete the selected ${documentTermLower}. Try again later or contact your system administrator for support.`
                : `The selected ${documentTermLower} has been successfully deleted. ${documentTerm} will no longer impact relevant reporting.`}
            </UserStyles.ModalPromptSubtitle>
            <UserStyles.DisableUserButtonsWrapper>
              <SubmitButton
                onClick={() => {
                  !deleteDocumentError && documentsCall.refresh();
                  setDeleteDocumentError(false);
                  setDeleteDocumentOutcomeModalIsVisible(false);
                }}
              >
                <span>close </span>
              </SubmitButton>
            </UserStyles.DisableUserButtonsWrapper>
            <UserStyles.ModalPromptCloseIcon
              onClick={() => {
                !deleteDocumentError && documentsCall.refresh();
                setDeleteDocumentError(false);
                setDeleteDocumentOutcomeModalIsVisible(false);
              }}
            />
          </UserStyles.ModalPromptWrapper>
        }
      />
      <Popper
        qa={`${qaBase}-${ElementType.MenuModal}-${
          anchorDocument && anchorDocument.id
        }`}
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
      >
        <PopperWrapper>
          <PopperRow
            data-testid={`${qaBase}-${ElementType.MenuItem}-viewDocuments-${
              anchorDocument && anchorDocument.id
            }`}
            onClick={() => viewDocument()}
          >
            <Text fontSize="0.9rem">View {documentTermLower}</Text>
            <PopperChevron />
          </PopperRow>
          <PopperRow
            data-testid={`${qaBase}-${
              ElementType.MenuItem
            }-viewDocumentsHistory-${anchorDocument && anchorDocument.id}`}
            onClick={() => {
              setPopperIsOpen(false);
              // setSelectedDocumentId(anchorEl && anchorEl.id);
              setShowDocumentDrawer(DocumentDrawerSection.History);
            }}
          >
            <Text fontSize="0.9rem">View {documentTermLower} history</Text>
            <PopperChevron />
          </PopperRow>
          <WriteContent roles={userWriteRoles}>
            <PopperRow
              data-testid={`${qaBase}-${ElementType.MenuItem}-deleteDocument-${
                anchorDocument && anchorDocument.id
              }`}
            >
              <DeleteDocumentMenuItem
                loading={deleteDocumentIsLoading}
                error={deleteDocumentError}
                closeMenu={() => setPopperIsOpen(false)}
                openConfirmationModal={() =>
                  setDeleteDocumentConfirmationModalIsVisible(true)
                }
              />
              <PopperChevron />
            </PopperRow>
          </WriteContent>
        </PopperWrapper>
      </Popper>

      <ContentWrapper id="mainContent">
        <PageHeader
          pageTitle={userCall?.data?.name ?? "User Profile"}
          paths={paths}
          qa={qaBase}
        >
          <WriteContent roles={userWriteRoles}>
            <span
              data-testid={`${qaBase}-${ElementType.Icon}-editPencil`}
              className={styles.editIconContainer}
            >
              <span
                onClick={() =>
                  navigate(`/people/users/edit-user/${participantId}`)
                }
              >
                <Icon type="pencil" className={styles.editIcon} />
              </span>
            </span>
          </WriteContent>
        </PageHeader>

        {/* TODO - Add StickyProfileHeader to the top of the page */}
        <div className="col-12">
          {stickyHeaderIsShowing && (
            <StickyProfileHeader
              email={userCall.data?.email}
              firstName={userCall.data?.firstName}
              icon={
                <i
                  className="icon-icons8-pencil ml-2"
                  style={{ fontSize: "0.66rem", cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/people/users/edit-user/${participantId}`)
                  }
                />
              }
              lastName={userCall.data?.lastName}
              primaryGroup={userCall.data?.primaryGroup?.name}
              supervisor={supervisor?.name}
            />
          )}
        </div>

        <div className={styles.profileWrapper}>
          <div className={styles.profileInformationWrapper}>
            <div className={styles.profileContentWrapper}>
              <div className={styles.profileContentHeader}>Group(s)</div>
              <div className={styles.profileContent}>
                {(participant &&
                  participant.id &&
                  listGroups(clientGroups, userCall.data?.primaryGroup?.id)) ||
                  "-"}
              </div>
            </div>
            <div className={styles.profileContentWrapper}>
              <div className={styles.profileContentHeader}>
                Group {supervisorTerm}
              </div>
              <div className={styles.profileContent}>
                {(supervisor && supervisor.name) || "-"}
              </div>
            </div>
            <div className={styles.profileContentWrapper}>
              <div className={styles.profileContentHeader}>Email</div>
              <div className={styles.profileContent}>
                <a href={`mailto: ${userCall.data?.email || ""}`}>
                  {userCall.data?.email || ""}
                </a>
              </div>
            </div>
          </div>

          <UserReportsCard
            filters={filters}
            onFilterByFormType={handleFilterByFormType}
            participantId={participantId}
            timeFilter={timeFilter}
            userName={userCall.data?.name ?? "User"}
            setTimeFilter={setTimeFilter}
          />
        </div>

        {/* Stats */}
        <Loader loading={userStatsCall.isLoading}>
          <div className={styles.statsWrapper}>
            {statsMap.map((e, i) => (
              <LegacyStatsCard
                header={
                  e.name === "Documents"
                    ? getDocumentsStatsNameIfSubmissionsFilterApplied(
                        dateRange,
                        timeFilter,
                        documentsTerm
                      )
                    : e.name
                }
                stretch
                key={i}
                notAvailable={!!e.notAvailable}
                type={e.type}
                value={getStat(e.key, userStatsCall.data)}
                valueName={e.valueName}
              />
            ))}
          </div>
        </Loader>

        <div className={styles.allUserDocs}>
          All of {userCall.data?.name ?? "User"}'s {documentsTerm}
        </div>

        <div className={styles.listContainer}>
          <div className={styles.filtersContainer}>
            <div className={styles.searchWrapper}>
              <SearchBar
                qa={`${qaBase}-${ElementType.TextInput}-searchBar`}
                onSearch={setSearchQuery}
                remoteClear={remoteClear}
              />
            </div>
            <div className={styles.filterOptions}>
              <div className={styles.filterSelect}>
                <FilterSelect
                  qa={`${qaBase}-${ElementType.SelectInput}-groupsFilter`}
                  name="groups"
                  value={group}
                  label=""
                  placeholder="All Groups"
                  options={generateGroupOptions(allGroupCall.data?.content)}
                  onChange={(option) => setGroup(option?.value)}
                />
              </div>
              <div className={styles.filterSelect}>
                <FilterSelect
                  qa={`${qaBase}-${ElementType.SelectInput}-formTypeFilter`}
                  name="formType"
                  value={formType}
                  label=""
                  placeholder="All Form Types"
                  options={formTypeOptions(formTypesCall.data || [])}
                  onChange={(option) => setFormType(option?.value)}
                />
              </div>
              <div className={styles.filterSelect}>
                <FilterSelect
                  qa={`${qaBase}-${ElementType.SelectInput}-statusesFilter`}
                  name="status"
                  placeholder="All Statuses"
                  value={status}
                  label=""
                  options={statusOptions}
                  onChange={(option) => setStatus(option?.value)}
                />
              </div>
              <div className={styles.filterSelect}>
                <FilterSelect
                  qa={`${qaBase}-${ElementType.SelectInput}-timeFilter`}
                  name="timeFilter"
                  value={timeFilter}
                  label=""
                  options={timeFilterOptions}
                  onChange={(option) =>
                    setTimeFilter(option?.value || TimeFilter.ALL_TIME)
                  }
                />
              </div>
              {timeFilter === "CUSTOM_RANGE" && (
                <div className={styles.dateRangeRow}>
                  <span className="visually-hidden" id="custom-range-label">
                    Custom Range
                  </span>
                  <DateRange
                    handleChange={setDateRange}
                    labelId="custom-range-label"
                    name="customRange"
                    onClear={() => {
                      setDateRange(undefined);
                      setTimeFilter(TimeFilter.ALL_TIME);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {/* Documents  */}
            <div className={styles.documentsList}>
              <Loader loading={documentsCall.isLoading}>
                <TableSummary
                  pageSize={documentsCall.data?.size || documentsSize}
                  currentPage={documentsCall.data?.number || documentsPage}
                  totalElements={documentsCall.data?.totalElements}
                  ofWhat={documentsTermLower}
                  exports={["print", "xls", "csv"]}
                  onExport={API.downloadDocuments}
                  exportParams={{
                    clientGroupId: group,
                    formTypeId: formType,
                    maxSubmissionDate,
                    minSubmissionDate,
                    status,
                    ownerId: participantId,
                    query: searchQuery,
                  }}
                  isFiltered={isFiltered}
                  onClearFilters={handleClearFilters}
                />
                <Table>
                  <TableHead>
                    <TableRow noBottomBorder>
                      <TableCell
                        qa={`${qaBase}-${ElementType.TableCell}-sort-documents`}
                        active={sorting.primarySort.type === "title"}
                        onClick={(dir) => sortBy("title", dir)}
                        sortDirection={sorting.title.direction}
                        minWidth="125px"
                        className={styles.firstCell}
                      >
                        <b>{documentTerm}</b>
                      </TableCell>
                      <TableCell
                        qa={`${qaBase}-${ElementType.TableCell}-sort-group`}
                        active={sorting.primarySort.type === "clientGroup.name"}
                        minWidth="150px"
                        onClick={(dir) => sortBy("clientGroup.name", dir)}
                        sortDirection={sorting["clientGroup.name"].direction}
                        width="175px"
                      >
                        <b>Group</b>
                      </TableCell>
                      <TableCell
                        qa={`${qaBase}-${ElementType.TableCell}-sort-formType`}
                        active={sorting.primarySort.type === "formTypeIds"}
                        minWidth="155px"
                        onClick={(dir) => sortBy("formTypeIds", dir)}
                        sortDirection={sorting["formTypeIds"].direction}
                        width="155px"
                      >
                        <b>Type</b>
                      </TableCell>
                      <TableCell
                        qa={`${qaBase}-${ElementType.TableCell}-sort-created`}
                        active={sorting.primarySort.type === "startDate"}
                        minWidth="110px"
                        onClick={(dir) => sortBy("startDate", dir)}
                        sortDirection={sorting.startDate.direction}
                        width="110px"
                      >
                        <b>Created</b>
                      </TableCell>
                      <TableCell
                        qa={`${qaBase}-${ElementType.TableCell}-sort-submitted`}
                        active={sorting.primarySort.type === "submissionDate"}
                        minWidth="130px"
                        onClick={(dir) => sortBy("submissionDate", dir)}
                        sortDirection={sorting.submissionDate.direction}
                        width="130px"
                      >
                        <b>Submitted</b>
                      </TableCell>
                      <TableCell
                        qa={`${qaBase}-${ElementType.TableCell}-sort-status`}
                        active={sorting.primarySort.type === "status"}
                        minWidth="100px"
                        onClick={(dir) => sortBy("status", dir)}
                        sortDirection={sorting.status.direction}
                        width="100px" // `In Progress` is on 2 lines unfortunately
                      >
                        <b>Status</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentsCall.data &&
                      documentsCall.data.content.map((document) => (
                        <TableRow
                          key={document.id}
                          qa={`${qaBase}-${ElementType.TableRow}-${document.id}`}
                        >
                          <TableCell
                            qa={`${qaBase}-${ElementType.TableCell}-name-${document.id}`}
                            className={styles.firstCell}
                          >
                            <Text
                              data-testid={`${qaBase}-${ElementType.Link}-viewDocument-${document.id}`}
                              fontSize={"0.875rem"}
                              fontWeight={400}
                              lineClamp={2}
                              textDecoration="underline"
                              onClick={() => {
                                setAnchorDocument(document);
                                setShowDocumentDrawer(
                                  DocumentDrawerSection.Document
                                ); // View document
                              }}
                            >
                              {`${document.title}`}
                            </Text>
                            <S.IdLabel>{`ID: ${document.id || "-"}`}</S.IdLabel>
                          </TableCell>
                          <TableCell
                            qa={`${qaBase}-${ElementType.TableCell}-group-${document.id}`}
                          >
                            <S.TableData>
                              {(document.clientGroup &&
                                document.clientGroup.name) ||
                                "-"}
                            </S.TableData>
                          </TableCell>
                          <TableCell
                            qa={`${qaBase}-${ElementType.TableCell}-type-${document.id}`}
                          >
                            <S.TableData>
                              {document.formSummary &&
                                document.formSummary.type &&
                                document.formSummary.type.name}
                            </S.TableData>
                          </TableCell>
                          <TableCell
                            qa={`${qaBase}-${ElementType.TableCell}-createdDate-${document.id}`}
                          >
                            <S.TableData>
                              {`${moment(document.startDate).format(
                                "MM/DD/YY"
                              )}`}
                            </S.TableData>
                            <S.Time>
                              {`${moment(document.startDate).format("hh:mma")}`}
                            </S.Time>
                          </TableCell>
                          <TableCell
                            qa={`${qaBase}-${ElementType.TableCell}-submittedDate-${document.id}`}
                          >
                            <S.TableData>
                              {`${moment(document.submissionDate).format(
                                "MM/DD/YY"
                              )}`}
                            </S.TableData>
                            <S.Time>
                              {`${moment(document.submissionDate).format(
                                "hh:mma"
                              )}`}
                            </S.Time>
                          </TableCell>
                          <TableCell
                            qa={`${qaBase}-${ElementType.TableCell}-status-${document.id}`}
                          >
                            <div className="d-flex flex-row justify-content-between">
                              <DefaultCell
                                value={prettifyDocumentStatus(document.status)}
                              />
                              {/* Context menu and sidebar */}
                              <ClickAwayListener
                                onClickAway={() => {
                                  if (popperIsOpen) {
                                    setPopperIsOpen(false);
                                  }
                                }}
                              >
                                <div className="d-flex flex-row justify-content-end">
                                  <ActionButton
                                    qa={`${qaBase}-${ElementType.Menu}-${document.id}`}
                                    label="menu"
                                    onClick={(e) => {
                                      setAnchorEl(e.currentTarget);
                                      setAnchorDocument(document);
                                      setTimeout(() => {
                                        setPopperIsOpen(true);
                                      }, 100);
                                    }}
                                  />
                                </div>
                              </ClickAwayListener>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {/* // Pagination */}
                {documentsCall.data && (
                  <div className={styles.paginationWrapper}>
                    <RowsPerPage
                      qaBase={`${qaBase}-view`}
                      pageSize={documentsCall.data?.size || documentsSize}
                      onClick={(size) => setDocumentsSize(size)}
                    />
                    <Pagination
                      qaBase={`${qaBase}-pagination`}
                      currentPage={documentsCall.data?.number || documentsPage}
                      onClick={(page) => setDocumentsPage(page)}
                      totalPages={documentsCall.data?.totalPages}
                    />
                    <BackToTop qa={`${qaBase}-${ElementType.Link}-backToTop`} />
                  </div>
                )}
              </Loader>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </>
  );
};
