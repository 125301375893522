import {
  API,
  CoreRedux,
  PageOfWorkOrderDTO,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";
import { createNewDocument } from "shared/src/components/Document/DocumentForm/prefills.helpers";
import { devices } from "shared/src/themes/mediaQueries";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { useAppDispatch } from "../../store/hooks";
import Tutorial from "../dashboard/Tutorial";
import { DocumentRouteState } from "../Document/Document";
import NewDocumentDrawer from "../Documents/components/NewDocumentDrawer/NewDocumentDrawer";
import styles from "./Dashboard.module.scss";
import { NewDocumentCard } from "./Desktop/NewDocumentCard";
import { RecentsContainer } from "./RecentsContainer/RecentsContainer";
import { WelcomeUserContainer } from "./WelcomeUserContainer/WelcomeUserContainer";
import { ReportsCard } from "../ReportsCard/ReportsCard";

const Dashboard: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const prefillAlternates = useSelector(CoreRedux.selectPrefillAlternates);
  const showTutorial = useSelector(CoreRedux.selectTutorialConfig)?.properties
    .showTutorial;
  const [showNewDocumentDrawer, setShowNewDocumentDrawer] = useState(false);
  const user = useSelector(CoreRedux.selectUser);

  const formTypesCall = useAPI(API.getAllFormTypes);
  const formsCall = useAPI(API.getAllForms, {
    format: API.FormVmFormat.SUMMARY,
  });
  const documentsTerm = useGroupTerm(
    "documents",
    "noun",
    "plural",
    "Documents"
  );
  const documentTerm = useGroupTerm("document", "noun", "singular", "Document");
  const rehuddleTerm = useGroupTerm("refocus", "noun", "singular", "Rehuddle");
  const workOrderTerm = useGroupTerm(
    "workOrder",
    "noun",
    "singular",
    "Work Order"
  );

  async function handleCreateDocument(formId: number, workOrderId?: number) {
    const document = await createNewDocument({
      formId,
      owner: user!,
      workOrderId,
      prefillAlternates,
    });
    const state: DocumentRouteState = { document };
    navigate(`/document/${document.id}`, { state });
  }

  async function handleSearchWorkOrders(
    workOrderId: string
  ): Promise<PageOfWorkOrderDTO> {
    return await API.getWorkOrders({ partialWorkOrderId: workOrderId });
  }

  const updateParticipantConfig = () => {
    dispatch(
      CoreRedux.setParticipantConfigForUser({
        active: true,
        keyName: "tutorial",
        properties: {
          showTutorial: false,
        },
      })
    );
  };

  return (
    <>
      <div className={styles.dashboard}>
        <div className={styles.grid}>
          <div className={styles.welcomeArea}>
            <WelcomeUserContainer className={styles.welcomeUserContainer} />
            <MediaQuery minWidth={devices.maxTablet}>
              <NewDocumentCard
                handleClick={() => setShowNewDocumentDrawer(true)}
              />
            </MediaQuery>
          </div>
          <div className={styles.reportsArea}>
            <ReportsCard />
          </div>
          <div className={styles.recentArea}>
            <RecentsContainer />
          </div>
        </div>
      </div>

      <NewDocumentDrawer
        formTypes={formTypesCall.data || []}
        formTypesLoading={formTypesCall.isLoading}
        forms={formsCall.data || []}
        formsLoading={formsCall.isLoading}
        isVisible={showNewDocumentDrawer}
        onCreateDocument={handleCreateDocument}
        onSearchWorkOrders={handleSearchWorkOrders}
        setIsVisible={setShowNewDocumentDrawer}
        terms={{
          document: {
            plural: documentsTerm,
            singular: documentTerm,
          },
          rehuddle: {
            singular: rehuddleTerm,
          },
          workOrder: {
            singular: workOrderTerm,
          },
        }}
      />

      <Tutorial open={showTutorial} endTutorial={updateParticipantConfig} />
    </>
  );
};

export default Dashboard;
