import { toTitleCase } from "../../util/toTitleCase";

const formatJsonToHtmlHelper = (json: any): string => {
  let html = "";

  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      html += `<p><strong>${toTitleCase(key)}:</strong></p>`;

      if (Array.isArray(json[key])) {
        html += "<ul>";
        json[key].forEach((item: any) => {
          const propsCount = Object.keys(item).length;
          let i = 0;
          html += "<ul>";
          for (const subKey in item) {
            if (item.hasOwnProperty(subKey)) {
              i++;
              html += `<li><strong>${toTitleCase(subKey)}:</strong> ${
                item[subKey]
              }</li>`;
              if (i === 1 && propsCount > 1) {
                html += "<ul>";
              }
              if (i === propsCount) {
                html += "</ul>";
              }
            }
          }
          html += "</ul>";
        });
        html += "</ul>";
      } else if (typeof json[key] === "object") {
        html += formatJsonToHtmlHelper(json[key]);
      } else {
        html += `<p>${json[key]}</p>`;
      }
    }
  }

  return html;
};

export const formatJsonToHtml = (json: any): string => {
  return formatJsonToHtmlHelper(json);
};

const formatJsonToTextHelper = (json: any, indentLevel: number = 0): string => {
  let text = "";
  const indent = "  ".repeat(indentLevel);

  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      text += `${indent}${toTitleCase(key)}:\n`;

      if (Array.isArray(json[key])) {
        json[key].forEach((item: any) => {
          const subKeys = Object.keys(item);
          subKeys.forEach((subKey, index) => {
            if (item.hasOwnProperty(subKey)) {
              if (index === 0) {
                text += `${indent}  - ${toTitleCase(subKey)}: ${
                  item[subKey]
                }\n`;
              } else {
                text += `${indent}    - ${toTitleCase(subKey)}: ${
                  item[subKey]
                }\n`;
              }
            }
          });
        });
      } else if (typeof json[key] === "object") {
        text += formatJsonToTextHelper(json[key], indentLevel + 1);
      } else {
        text += `${indent}  ${json[key]}\n`;
      }
    }
  }

  return text;
};

export const formatJsonToText = (json: any): string => {
  return formatJsonToTextHelper(json, 0);
};
