import { UserStatus } from "@rtslabs/field1st-fe-common";

// User status filter, transform options to BE-friendly values
export const mapStatus = (status?: number): UserStatus | undefined => {
  switch (status) {
    case 1:
      return UserStatus.ACTIVE;
    case 2:
      return UserStatus.DISABLED;
    case 3: // Not registered
      return UserStatus.NOT_ACTIVATED;
    case 4: // N/A
      return UserStatus.NOT_APPLICABLE;
    case -1:
    default:
      return undefined;
  }
};

// If this returns true, we display an Enable/Disable user menu item
export const showDisableEnableItem = (status) => {
  if (status && ["ACTIVE", "DISABLED"].includes(status)) {
    return true;
  }
  return false;
};

// Activation endpoint accepts a boolean, so this maps the status to disable/enable a user
export const getActivationStatusForPayload = (status?: UserStatus | null) => {
  switch (status) {
    case "ACTIVE":
      return false;
    case "DISABLED":
      return true;
    default:
      return null;
  }
};

// If this returns true, we display a "resend validation/activation email" menu item
export const showEmailValidationItem = (status) => {
  if (status === "NOT_ACTIVATED") {
    return true;
  }
  return false;
};

// Prettifies user status in Users table
export const getActivationStatusForTable = (status) => {
  switch (status) {
    case "ACTIVE":
      return "Active";
    case "DISABLED":
      return "Disabled";
    case "NOT_ACTIVATED":
      return "Not registered";
    case "NOT_APPLICABLE":
      return "N/A";
    default:
      return "";
  }
};

// Enable/disable user partial copy
export const getEnabledOrDisabledWording = (currentStatus) => {
  switch (currentStatus) {
    case "ACTIVE":
      return "disable";
    case "DISABLED":
      return "enable";
    default:
      return "";
  }
};

// Used for button inside modal, when enabling/disabling user
export const getActivationStatusButtonLabel = (status) => {
  switch (status) {
    case "ACTIVE":
      return "Disable user";
    case "DISABLED":
      return "Enable user";
    default:
      return "";
  }
};
