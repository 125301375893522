import React, { useContext, useState, useEffect, FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction, {
  BottomNavigationActionProps,
} from "@mui/material/BottomNavigationAction";

import styles from "./DashboardBottomNavigation.module.scss";
import BottomNavigationContext from "./Context";
import { NavigationExpansionDrawerContent } from "shared/src/components/navigation/DashboardBottomNavigation/NavigationExpansionDrawerContent";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import { MainNavigationCopyKey } from "@rtslabs/field1st-fe-common";
import { Icon } from "shared/src/components/Icon/Icon";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { DashboardBottomNavProps } from "shared/src/components/navigation/DashboardBottomNavigation/types";
import { CircularMenu } from "shared/src/components/navigation/DashboardBottomNavigation/CircularMenu/CircularMenu";
import AiChatDrawer from "../Document/AiChatDrawer/AiChatDrawer";

interface BasicActionProps extends BottomNavigationActionProps {
  isActive?: boolean;
}

const BasicAction: FC<BasicActionProps> = ({ isActive, ...rest }) => (
  <BottomNavigationAction
    className={joinClassNames(styles.basicAction, isActive && styles.active)}
    {...rest}
  />
);

const DashboardBottomNavigation: FC<DashboardBottomNavProps> = ({
  drawerContent,
  mainNavigationConfig,
}) => {
  const { setDrawerIsVisible } = useContext(BottomNavigationContext);
  const [isChatDrawerOpen, setIsChatDrawerOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Navigation expansion drawer
  const [navigationDrawerVisible, setNavigationDrawerVisible] =
    useState<boolean>(false);

  // Hide drawer when location changes (user navigated away)
  useEffect(() => {
    setNavigationDrawerVisible(false);
  }, [location.pathname]);

  const getCopy = (key: MainNavigationCopyKey): string | undefined => {
    let copy;
    if (mainNavigationConfig?.mobileCopy?.[key]) {
      copy = mainNavigationConfig.mobileCopy[key];
    } else if (mainNavigationConfig?.copy?.[key]) {
      copy = mainNavigationConfig.copy[key];
    }
    return copy;
  };

  return (
    <div className={styles.bottomNavigation}>
      <AiChatDrawer
        open={isChatDrawerOpen}
        onClose={() => setIsChatDrawerOpen(false)}
      />
      {/* Navigation expansion drawer */}
      <Drawer
        anchor="right"
        id="menuExpansionDrawer"
        onClose={() => setNavigationDrawerVisible(false)}
        isOpen={navigationDrawerVisible}
      >
        <NavigationExpansionDrawerContent>
          {drawerContent}
        </NavigationExpansionDrawerContent>
      </Drawer>
      <BottomNavigation showLabels className={styles.bottomNavigationBar}>
        <BasicAction
          label="Home"
          icon={<Icon className={styles.icon} type="a_home" />}
          isActive={location.pathname === "/"}
          onClick={() => navigate("/")}
        />

        <BasicAction
          label={getCopy(MainNavigationCopyKey.documents) || "Documents"}
          icon={<Icon className={styles.icon} type="document" />}
          isActive={location.pathname === "/documents"}
          onClick={() => navigate("/documents")}
        />

        <BottomNavigationAction
          label=""
          component={React.forwardRef<HTMLButtonElement>((props, ref) => (
            <CircularMenu
              onNewDoc={() => setDrawerIsVisible(true)}
              onChat={() => setIsChatDrawerOpen(true)}
              ref={ref}
            />
          ))}
          showLabel={false}
          icon={<div />}
          style={{}}
        />

        <BasicAction
          label={getCopy(MainNavigationCopyKey.reports) || "Reports"}
          icon={<Icon className={styles.icon} type="combo_chart" />}
          isActive={location.pathname === "/reports"}
          onClick={() => navigate("/reports")}
        />

        <BasicAction
          label={getCopy(MainNavigationCopyKey.menu) || "Menu"}
          icon={<Icon className={styles.icon} type="menu" />}
          onClick={() => setNavigationDrawerVisible(true)}
        />
      </BottomNavigation>
    </div>
  );
};

export default DashboardBottomNavigation;
